<template>
	<v-container pa-0 fluid fill-height>
		<v-card flat>
			<v-row class="a" justify="center">
				<v-col 
				pt-3 
				cols="10"
				sm="8"
				md="6"
				lg="5"
				xl="3"
				>
					<v-form ref="form" v-model="loginValid" @submit.prevent="validate">
						<v-row justify="center">
							<v-col 
							cols="8"
							lg="6"
							xl="4">
								<v-img :src="require('@/assets/bbg-express.png')" aspect-ratio="1"></v-img>
							</v-col>
						</v-row>
						<v-row class="mt-15">
							<v-col>     
								<v-text-field
								v-model="tracking_number"
								label="Tracking#"
								variant="outlined"
								:rules="trackingRules"
								required
								></v-text-field>
							</v-col>
						</v-row>
						<v-row dense justify="center">  
							<v-col class="text-center">
								<v-btn
								size="x-large"
								variant="flat"
								color="#FE7167"
								rounded="lg"
								class="white--text"
								@click="validate"
								:loading="loading"
								:disabled="loading"
								>
								Track My Order
								</v-btn> 
								<div>{{ msg }}</div>
							</v-col>         
						</v-row>
					</v-form>
				</v-col>
			</v-row>
		</v-card>

		<v-card flat v-show="shippingDetailsToggle">
			<v-row justify="center">
				<v-col 
				pt-3 
				cols="10"
				sm="8"
				md="6"
				lg="5"
				xl="3"
				>
				<v-list>
					<v-list-item
						v-for="shippingDetail in shippingDetails"
						:key="shippingDetail.created_at"
						:subtitle="new Date(shippingDetail.created_at).toString()"
						:title="shippingDetail.status"
					></v-list-item>
				</v-list>
					
				</v-col>
			</v-row>
		</v-card>
	</v-container>
</template>

<script>
import { check_tracking } from '@/services/api';

export default {
	name: 'ShipmentTracking',
	methods: {
		async validate () {
			this.msg = ''
			this.shippingDetails = []
			this.shippingDetailsToggle = false
			const { valid } = await this.$refs.form.validate()

			if (valid) {
				await this.handleSubmit(this.tracking_number.trim())
			} else {
				alert('please fix your input')
			}
		},
		async handleSubmit(tracking_number){
			this.loading = true
			this.msg = `checking status on shipment #${tracking_number}...`
			const trackingRes = await check_tracking(tracking_number)
			if (trackingRes.status == 200) {
				if (trackingRes.data.Count > 0) {
					const history = trackingRes.data.Items
					history.sort((a,b) => b.created_at - a.created_at)
					this.shippingDetails = history
					this.shippingDetailsToggle = true
					this.msg = `Latest status for shipment #${tracking_number}:  ${history[0].status}`
					this.loading = false
				} else {
					this.msg = "No shipments found with this tracking#, please verify your input and try again"
					this.loading = false
				}

			} 
		},

	},
	data () {
		return {
			tracking_number: '',
			msg:'',
			loading: false,
			trackingRules: [
				v => !!v || 'Tracking number is required',
				v => (!isNaN(v)) || 'Tracking number can only contain numbers',
				v => Number.isInteger(Number(v)) || "Please follow correct format",
				v => v > 0 || "Please follow correct format"
			],
			loginValid: false,
			shippingDetailsToggle: false,
			shippingDetails: []
		}
	}
};
</script>

<style scoped>
.a {
	padding-top: 20vh
}

textarea:focus, input:focus {
	color: #FE7167 !important;
}
</style>