import axios from "axios";

export const check_tracking = async (tracking_number) => {
    try{
        
        const res = await axios({
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
              },
            url: process.env.VUE_APP_ADMIN_PORTAL_URL + '/bbgex',
            data: {
                "event": "getShippingHistory",
                "tracking_number": tracking_number
            },
        })
        return res;
    }
    catch (err) {
        return error_handling(err)
    }
}

const error_handling = (err) => {
    if (err.response) {
        // The request was made and the server responded with a status code
        // that falls out of the range of 2xx
        return {status: 'error', alertTitle:'Server can not verify your identity', alertContent:err.response.data}
    } else if (err.request) {
        // The request was made but no response was received
        // `err.request` is an instance of XMLHttpRequest in the browser and an instance of
        // http.ClientRequest in node.js
        return {status: 'error', alertTitle:'Server did not respond to request', alertContent:err.request}
    } else {
        // Something happened in setting up the request that triggered an Error
        return {status: 'error', alertTitle:'Error setting up request', alertContent:err.message}
    }
}
